#login {
    height: unset !important;
}

#login>.container {
    margin-top: 187px;
    margin-bottom: 58px;
}

.password {
    margin-top: 10px;
    background-color: rgba(110, 43, 90, 0.40);
    color: rgb(21, 9, 36);
    /* padding: 10px; */
    border-radius: 5px;
    outline: none;
    border: none;
    width: 140px;
    font-size: 10;
    cursor: pointer;
    background-color: rgba(209, 5, 5, 0.829);
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

}
.password:hover{
    background-color: rgb(253, 0, 0);
    color: white;
}

