/* .adress{
    margin: 20px;
    padding: 15px;
}

.adress li{
    cursor: pointer;
   
}
.adress li:hover{
    color: red;
} */
.about{
    margin: 10px 0;
    font-weight: bold;
}
.aboutpage{
    display: flex;
    justify-content: center;
}

.list-none a {
    text-decoration: none;
    color: #000;
    margin: 5px 0;
}

.home_footer {
    padding-bottom: 0 !important;
}

.social {
    /*border: 2px solid red;*/
}

.social .fab {
    /*margin-right: 30px;*/

}

.none-on-small {
    display: block;
}

@media screen and (max-width: 768px) {
    .none-on-small {
        display: none;
    }
}

.logo {
    /* margin-top: -30px; */
    /* margin-right: 30px; */
    margin-bottom: 10px;
}