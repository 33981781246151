.pro-user-name {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .appbtn {
    margin-top: 30px;
    text-align: left;
  }
}

.appbtn .btn {
  background: #212529 !important;
  padding: 15px 10px;
  color: #fff;
}

@media (max-width: 1440px) {
  .offcanvas-end form a {
    font-size: 18px;
    line-height: 25px;
    padding: 6px 30px;
  }

  .offcanvas-end form a {
    background: #0078cf;
    background: var(--bg_color);
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    padding: 8px 30px;
  }

  .offcanvas-end a {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    padding: 0;
  }
}

@media (max-width: 992px) {
  .offcanvas-end ul li {
    margin: 0 0 20px;
  }
  .emon {
    height: 100px!important;
  }
}

@media screen and (max-width: 768px) {
  .emon {
    height: 100%!important;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-end {
    width: 250px !important;
  }
}

.offcanvas-end a.active {
  color: #0078cf !important;
}



.profile-avatar {
  position: relative;
}

.profile-avatar:hover .logoutButton {
  display: block;
}

.logoutButton { 
  display: none;
  position: absolute;
  background: none;
  padding: 8px;
  left: 0;
  top: 100%;
  transform: translateX(-6px);
}

.logoutButton .item {
  border-radius: 5px;
  background-color: #f1f1f1;
  padding: 5px 10px;
  cursor: pointer;
}
