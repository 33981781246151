.showOnlyOnMobile,
.footerOnMobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .display-none-on-phone {
        display: none;
    }

    /* 
    #banner::before {
        height: 89vh !important;
    } */

    .showOnlyOnMobile {
        display: block;
        margin-top: 60px;
    }

    .dontShowOnMobile {
        display: none;
    }

    .footerOnMobile {
        display: block;
    }

    .img .overlay_img {
        position: absolute;
        padding-bottom: 400px;

    }

}

#banner .showOnlyOnMobile a {
    font-size: 18px !important;
    line-height: inherit !important;
    background: none !important;
    padding: inherit !important;
    border-radius: none;
    margin-top: 0 !important;
    color: #004bbb !important;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;

}

.ads {
    border: 2px solid green;
    height: 100%;
}

.my-caption {

    background-color: rgb(122, 40, 203);
    right: unset;
    bottom: 38%;
    padding: 30px;

    display: none;
}



/* @media screen and (max-width: 768px) {
    #banner::before {
        height: 100vh !important;
    }
} */

.caro-img {
    max-height: 800px;
}

.regOnlyOnMobile {
    background: green !important;
    display: none;
}

@media screen and (max-width: 768px) {
    .regOnlyOnMobile {
        display: block;
        width: 150px;
    }

    .caro-img {
        height: 220px;
    }
}

.caro-img {
    border: 3px solid #84397D;
    border-radius: 15px;
}

@media screen and (min-width: 1440px) {

    .caro-img {
        height: auto;
        max-height: unset !important;
    }
}

@media screen and (min-width: 540px) and (max-width: 1024px) {
    .caro-img {
        height: 430px;
    }
  }

.adContainer {
    margin-top: 20px;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #fff;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 10px;
    font-weight: 700 !important;
    transition: all 0.4s;
}

.adContainer:hover {
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 24px;
    transform: scale(1);
}

@media screen and (max-width: 768px) {
    .adContainer {
        height: 150px;
    }
}

@media screen and (max-width: 425px) {
    .adContainer {
        height: 100px;
    }
}

.adsbann .col-4:nth-child(1) .adContainer {
    background-color: #00A2FA;
}

.adsbann .col-4:nth-child(2) .adContainer {
    background-color: #00C274;
}

.adsbann .col-4:nth-child(3) .adContainer {
    background-color: #FF6B7F;
}

#banner .d_flex {
    padding: 0 150px;
}

@media screen and (min-width: 540px) and (max-width: 1024px) {
    #banner .d_flex {
        padding: 0 50px;
    }
  }
  

/* The container holding the timer */
.home_timer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
  }
  
  /* The style for each individual home_timer digit */
  .home_timer span {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    background-color: #eee;
    color: #333;
    margin: 0.5rem;
    border-radius: 0.25rem;
  }

.homeBanner {
    padding: 50px 0 !important;
    margin-top: 0 !important;
    padding-top: 170px !important;
}

.banner_content img {
    width: 100%;
    height: 500px;
}

.banner_content .overlay_img {
    top: 119px !important;
}

@media screen and (min-width: 540px) and (max-width: 1024px) {
    .banner_content .overlay_img {
        top: 106px !important;
    }

    .banner_content img {
        height: 341px;
    }
  }
  

.playbutton {
    display: flex;
    justify-content: center;
    align-items: start;

}

.playbutton a {
    margin-top: 0 !important;
}
  

@media screen and (max-width: 500px) {
    #banner .d_flex {
        padding: 0;
    }

    .banner_content img {
        width: 100%;
        height: 300px;
    }

    .home_timer {
        margin-top: -70px !important;
    }

    .banner_content .overlay_img {
        top: 60px !important;
    }
}
