.card {
    height: 300px;
    /* border: 2px solid red; */
}

.card a {
    height: 80%;
}

.card a img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: 50% 0;
}